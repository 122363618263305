import React from 'react';
import {Button, ButtonGroup} from 'react-bootstrap';
import AsyncSelect from '../../components/Select/AsyncSelect';
import BootstrapInput from '../../components/FormInput/BootstrapInput2';
import Switch from '../../components/Switch/Switch';
import DataViewColumn from '../../components/DataViewColumn/DataViewColumn';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {set, cloneDeep} from 'lodash';
import {SORT_ORDER} from '../../components/DataViewColumn/DataViewColumn';
import withContext from '../../hoc/withContext';
import axios from '../../helpers/axiosWebEntry';
import axiosData from '../../helpers/axiosData';
import axiosUser from '../../helpers/axiosUser';
import {array_move} from '../../helpers/utils';
import * as yup from 'yup';

const schema = yup.object().shape({
    Name: yup.string().required('Required.'),
    URL: yup.string().required('Required.'),    
    Roles: yup.array().required('Required.'),
    WebEntries: yup.array().required('Required.'),
});

class Form extends React.Component {
    state = {
        dirty: false,
        data: {
            Name: '',
            URL: '',
            WebEntries: [],
            Roles: [],
            NewEventTypePage: undefined,
            VisibleInMenu: false,
            Columns: []
        },
        error:{}
    }
    
    componentDidMount=()=>{
        this.props.context.setHeading('Data View Form');
        const {match: {params}} = this.props;
        

        if(params.id){
            this.props.context.showLoading();
            axios.get(`/eventlistview/${params.id}`)
            .then(response => {
                const data = JSON.parse(response.data);
                data.WebEntries = data.WebEntries.map(el=>({value:el.EventTypePageEntity.ID, label:el.EventTypePageEntity.Name}));
                data.Roles = data.Roles.map(el=>({value:el.IdentityRole.Id, label:el.IdentityRole.Name}));
                data.NewEventTypePage = data.NewEventTypePage ? {label: data.NewEventTypePage.Name, value: data.NewEventTypePage.ID}: undefined;
                data.Columns.sort((a,b) => a.Order > b.Order ? 1 : -1);
                data.Columns = data.Columns.map(el=>({
                    EventField: el.EventField.Name,
                    DisplayName: el.DisplayName,
                    Width: el.Width,
                    Visible: el.Visible,
                    Filter: el.Filter,
                    WordWrap: el.WordWrap,
                    ColumnOrder: el.ColumnOrder!==undefined ? SORT_ORDER.find(o=>o.value === el.ColumnOrder) : undefined
                }));
                this.setState({data:data});
                this.props.context.hideLoading();
            })
            .catch(error=>{
                console.log(error);
                this.props.context.hideLoading();
            });
        }
    }

    getRoles = (query) => {
        return axiosUser.get('/roles', {params:{name:query}}).then(response=>{
            if (response && response.data){
                const tmp = JSON.parse(response.data).map((el, index)=>({value:el.Id, label:el.Name}));
                return tmp;
            }
            return [];
        }).catch(error=>console.log(error));
    }

    getEntries = (query) => {
        return axios.get('/eventtypepage', {params:{name:query}}).then(response=>{
            if (response.data){
                const tmp = response.data.map((el, index)=>({value:el.id, label:el.name}));
                return tmp;
            }
            return [];
        }).catch(error=>console.log(error));
    }

    onChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevState=>{
            const data = cloneDeep(prevState.data);
            const error = {...this.state.error}; 
            set(data, name, value);
            set(error, name, undefined);
            return {data:data, dirty: true, error: error};
        });
    }

    onClose=()=>{
        if(!this.state.dirty){
            this.props.history.goBack();
            return;
        }
        if(window.confirm('There are unsaved changes, do you want to leave?')){
            this.props.history.goBack();
            return;
        }
    }

    onRemove=(index)=>{
        const data = {...this.state.data};
        data.Columns.splice(index, 1);
        this.setState({data:data});
    }

    onDragEnd = (fields) => {
        if(!fields.destination)
            return;
        const from = fields.source.index;
        const to = fields.destination.index;
        const data = {...this.state.data}
        array_move(data.Columns, from, to);
        this.setState({data:data});
    }

    loadColumns = () => {
        this.props.context.showLoading();
        axiosData.post('/old/calls/custom' ,{url: this.state.data.URL})
        .then(response=>{
            const systemColumns = ['_EventID', '_NodeId', '_EventTime', '_StorageTime', '_EventTypeID', '_ParentID', '_ID',
                '_CreatedBy', '_CreatedAt', '_ModifiedBy', '_ModifiedAt', '_LockedBy', '_LockedAt', '_UnlockedBy', '_UnlockedAt', '_LockState', '_OwnedBy'];
            const data = {...this.state.data};
            const fields = {};
            
            response.data.Events.Values.forEach(ev=>{
                for (let key in ev){
                    if (key in fields || systemColumns.includes(key))
                        continue;
                    fields[key] = {
                        EventField: key,
                        DisplayName: '',
                        Width: '',
                        Filter: '',
                        WordWrap: false,
                        Visible: true,
                        ColumnOrder: 0
                    }
                }
            });
            data.Columns = Object.keys(fields).map(el=>fields[el]);
            this.setState({data:data});
            this.props.context.hideLoading();
        })
        .catch(error=>{
            console.log(error);
            this.props.context.pushAlert({variant:'danger', message:'Unable load or parse URL response structure.'});
            const data = {...this.state.data};
            data.Columns = [];
            this.setState({data:data});
            this.props.context.hideLoading();
        });
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const name = e.target.name;
        schema.validate(this.state.data, {abortEarly:false})
        .then(valid=>{
            this.props.context.showLoading();
            const {match: {params}} = this.props;
            const data = {...this.state.data};
            data.Roles = data.Roles.map(item=>({IdentityRoleId: item.value}));
            data.WebEntries = data.WebEntries.map(el=>({
                EventTypePageEntity: {ID:el.value},
    
            }));
            data.NewEventTypePage = data.NewEventTypePage ? {ID:data.NewEventTypePage.value}: undefined;
            data.Columns = data.Columns.map((col,i)=>({
                ...col,
                ColumnOrder: col.ColumnOrder? col.ColumnOrder.value : undefined,
                Order: i,
                Width: col.Width ? col.Width : 0,
                EventField: {Name: col.EventField}
            }));
            if(params && params.id){
                axios.put(`/eventlistview/${data.ID}`, data)
                .then(response=>{
                    this.setState({dirty: false});
                    this.props.context.pushAlert({variant:'success', message: 'Item updated.'});
                    this.props.context.hideLoading();
                    if(name==='SaveClose')
                        this.props.history.push('/dataview');
                }).catch(error=>{
                    this.props.context.pushAlert({variant:'danger', message: 'Error occured. Item was not updated.'});
                    console.log(error);
                    this.props.context.hideLoading();
                });
            } else {
                axios.post(`/eventlistview`, data)
                .then(response=>{
                    this.setState({dirty: false});
                    this.props.context.pushAlert({variant:'success', message: 'Item created.'});
                    this.props.context.hideLoading();
                    if(name==='SaveClose')
                    this.props.history.push('/dataview');
                    else
                        this.props.history.push(`/dataview/update/${response.data.id}`);
                }).catch(error=>{
                    this.props.context.pushAlert({variant:'danger', message: 'Error occured. Item was not created.'});
                    console.log(error);
                    this.props.context.hideLoading();
                });
            }
        })
        .catch(errors=>{
            const error = {};
            errors.inner.forEach(el=>{
                set(error,el.path, el.message);
            });
            this.setState({error:error});
        })
        
            
        
    }

    render() {
        const error = this.state.error;
        return(
            <div className="content-overlay">
            <form>
                <fieldset>
                    <legend>Basic Settings</legend>
                    <BootstrapInput name="Name" label="Name" onChange={this.onChange} value={this.state.data.Name} error={error}/>
                    <BootstrapInput name="URL" placeholder="URL" onChange={this.onChange} value={this.state.data.URL} error={error} prepend={()=>(<Button onClick={this.loadColumns}>Load</Button>)}/>
                    <BootstrapInput name="WebEntries" label="Display Pages" onChange={this.onChange} value={this.state.data.WebEntries} error={error} loadOptions={this.getEntries} isMulti component={AsyncSelect}/>
                    <BootstrapInput name="Roles" label="Available for Roles" onChange={this.onChange} defaultOptions value={this.state.data.Roles} error={error} isMulti loadOptions={this.getRoles} component={AsyncSelect}/>
                    <BootstrapInput name="NewEventTypePage" label="New Data Entry Form" info="Leave blank to disable Add action" onChange={this.onChange} isClearable value={this.state.data.NewEventTypePage} error={error} loadOptions={this.getEntries} component={AsyncSelect}/>
                    <BootstrapInput name="VisibleInMenu" label="Visible in Menu" disableLabel value={this.state.data.VisibleInMenu} error={error} onChange={this.onChange} component={Switch}/>
                    <hr/>
                </fieldset>
                <fieldset>
                    <legend>Columns</legend>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="columns" type="COLS">
                                {(provided, snapshot)=>(
                                    <div ref={provided.innerRef}>
                                        {this.state.data.Columns.map((col, index)=>(
                                            <DataViewColumn value={col} onRemove={()=>this.onRemove(index)} key={index} index={index} name={`Columns[${index}]`} onChange={this.onChange}/>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                </fieldset>
                <hr/>
                <ButtonGroup>
                    <Button onClick={this.onClose} variant="warning">Exit</Button>
                    <Button onClick={this.handleSubmit} name="Save" variant="primary">Save</Button>
                        <Button onClick={this.handleSubmit} name="SaveClose" variant="success">Save &amp; Exit</Button>
                </ButtonGroup>
            </form>
            </div>
        );
    }
}

export default withContext(Form);