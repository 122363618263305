import React from 'react';
import withContext from '../../hoc/withContext';
import BootstrapInput from '../../components/FormInput/BootstrapInput2';
import Switch from '../../components/Switch/Switch';
import Select from '../../components/Select/Select';
import AsyncSelect from '../../components/Select/AsyncSelect';
import {Button, ButtonGroup} from 'react-bootstrap';
import axios from '../../helpers/axiosWebEntry';
import axiosConfig from '../../helpers/axiosConfig';
import axiosUser from '../../helpers/axiosUser';
import {array_move} from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {set, cloneDeep} from 'lodash';
import Groups from '../../components/DataEntryGroup/Groups'
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import AssocView from '../../components/AssociatedEventSettings/AssociatedEventSettings';
import * as yup from 'yup';

const pageTypes=[{value:0, label:'Entry'}, {value:1, label:'View'}];

const schema = yup.object().shape({
    Name: yup.string().required('Required.'),
    PageType: yup.mixed().required('Required.'),
    EventType: yup.mixed().required('Required.'),
    Roles: yup.array().required('Required.'),
    Groups: yup.array().of(yup.object().shape({
        Name: yup.string().required('Required.'),
    })),
    AssociatedEventDataViews: yup.array().of(yup.object().shape({
        Label: yup.string().required('Required.'),
        EventsDataView: yup.mixed().required('Required.'),
    })),
});

class Form extends React.Component {
    state={
        data:{
            ID: '',
            Name:'', 
            PageType: {value:0, label: 'Entry'},
            Roles:[],
            EventType: undefined,
            Groups: [],
            AssociatedEventDataViews: [],
            AllowAssociatedEvents: false
        },
        dirty: false,
        error: {}
    }

    componentDidMount = () => {
        const {match: {params}} = this.props;
        this.props.context.setHeading('Data Entry Form');
        this.props.context.showLoading();

        if (!params.id){
            this.setState({data:{
                Name: '',
                PageType: {value: 0, label: 'Entry'},
                EventType: undefined,
                Roles: [],
                AllowAssociatedEvents: false,
                DisableAttachments: false,
                Groups: [],
                AssociatedEventDataViews: []
            }});
            this.props.context.hideLoading();
        } else {
            axios.get(`/eventtypepage/${params.id}`)
            .then(response => {
                const data = JSON.parse(response.data);
                data.PageType = pageTypes.find((el)=>el.value === data.PageType);
                data.EventType = {value: data.EventType.ID, label: data.EventType.Name};
                data.Roles = data.Roles.map(el=>({value:el.IdentityRole.Id, label:el.IdentityRole.Name}));
                data.Groups = data.Groups.map(el=>({...el, ID: undefined}));
                data.AssociatedEventDataViews = data.AssociatedEventDataViews.map(el=>({
                    ...el,
                    ID: undefined,
                    EventsDataView: {value: el.EventsDataView.ID, label: el.EventsDataView.Name}
                }));
                data.Groups.forEach(g=>{
                    g.EventFieldsGroupEventFields.sort((a,b) => a.Order > b.Order ? 1 : -1);
                });
                data.Groups.sort((a,b) => a.Order > b.Order ? 1 : -1);
                this.setState({data:data});
                this.props.context.hideLoading();
            })
            .catch(error=>{
                console.log(error);
                this.props.context.hideLoading();
            });
        }
    } 

    getETs = (query) => {
        return axiosConfig.get('/eventtype', {params:{name:query}}).then(response=>{
            if (response && response.data){
                const tmp = JSON.parse(response.data).map((el, index)=>({value:el.ID, label:el.Name}));
                return tmp;
            }
            return [];
        }).catch(error=>console.log(error));
    }
    
    getRoles = (query) => {
        return axiosUser.get('/roles', {params:{name:query}}).then(response=>{
            if (response && response.data){
                const tmp = JSON.parse(response.data).map((el, index)=>({value:el.Id, label:el.Name}));
                return tmp;
            }
            return [];
        }).catch(error=>console.log(error));
    }

    loadET = (e) => {
        this.onChange(e);
        this.props.context.showLoading();
        const eventValue=e.target.value;
        axiosConfig.get(`/eventtype/${eventValue.value}`)
        .then(response=>{
            const data = {...this.state.data};
            data.EventType = eventValue;
            const tmp = JSON.parse(response.data);
            const group = {
                Name:'',
                InfoText:'',
                Collapsed: false,
                EventFieldsGroupEventFields: tmp.EventFields.map((el, index)=>({
                    DisplayName: '',
                    DefaultValue: '',
                    Visible: true,
                    ReadOnly: false,
                    UseTextarea: false,
                    UseBtnWidget: true,
                    EventField: {Name: el.EventField.Name, ID:el.EventField.ID, BuiltInType:el.EventField.BuiltInType, ValidationFunctionName:el.EventField.ValidationFunctionName}
                }))
            };
            data.Groups=[];
            data.Groups.push(group);
            this.setState({data:data});
            this.props.context.hideLoading();
        })
        .catch(error=>{
            console.log(error);
            this.props.context.hideLoading();
        });
    }

    onChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevState=>{
            const data = cloneDeep(prevState.data);
            const error = {...this.state.error}; 
            set(data, name, value);
            set(error, name, undefined);
            return {data:data, dirty: true, error: error};
        });
    }

    onClose=()=>{
        if(!this.state.dirty){
            this.props.history.goBack();
            return;
        }
        if(window.confirm('There are unsaved changes, do you want to leave?')){
            this.props.history.goBack();
            return;
        }
    }

    onDragEnd = (fields) => {
        if(!fields.destination)
            return;
        const from = fields.source.index;
        const to = fields.destination.index;
        const data = {...this.state.data}

        if(fields.destination.droppableId === fields.source.droppableId){
            if(fields.type === 'GROUPS'){    
                array_move(data.Groups, from, to);
            } else {
                const index = fields.destination.droppableId.replace('group', '');
                array_move(data.Groups[index].EventFieldsGroupEventFields, from, to);
            }
        } else {
            const sourceGroup = fields.source.droppableId.replace('group', '');
            const destGroup = fields.destination.droppableId.replace('group', '');
            const sourceArray = cloneDeep(data.Groups[sourceGroup]);
            const destArray = cloneDeep(data.Groups[destGroup]);
            const item = sourceArray.EventFieldsGroupEventFields.splice(from, 1)[0];
            destArray.EventFieldsGroupEventFields.splice(to, 0, item);
            data.Groups[sourceGroup] = sourceArray;
            data.Groups[destGroup] = destArray;
        }
        this.setState({data: data});
    }

    addGroup=()=>{
        this.setState(prevState=>{
            const data = {...prevState.data};
            data.Groups= [...data.Groups, {
                Name:'',
                InfoText:'',
                Collapsed: false,
                EventFieldsGroupEventFields:[] 
            }];
            return {data:data};
        });
    }

    addAssocView=()=>{
        this.setState(prevState=>{
            const data = {...prevState.data};
            data.AssociatedEventDataViews= [...data.AssociatedEventDataViews, {
                Label:'',
                EventsDataView: undefined,
                UrlParams: ''
            }];
            return {data:data};
        });
    }

    removeAssocView=(index)=>{
        this.setState(prevState=>{
            const data = {...prevState.data};
            data.AssociatedEventDataViews = [...data.AssociatedEventDataViews];
            data.AssociatedEventDataViews.splice(index, 1);
            return {data:data};
        });
    }

    removeGroup=(index)=>{
        this.setState(prevState=>{
            const data = {...prevState.data};
            data.Groups = [...data.Groups];
            data.Groups.splice(index, 1);
            return {data:data};
        });
    }

    handleSubmit=(e)=>{
        const name = e.target.name;
        e.preventDefault();
        schema.validate(this.state.data, {abortEarly:false})
        .then(valid=>{
            this.props.context.showLoading();
            const {match: {params}} = this.props;
            const data = {...this.state.data};
            data.EventType.ID = data.EventType.value;
            data.Roles = data.Roles.map(item=>({IdentityRoleId: item.value}));
            data.PageType = data.PageType.value;
            data.AssociatedEventDataViews.forEach((item, index)=>{
                item.EventsDataView.ID = item.EventsDataView.value;
            });
            data.Groups.forEach((g, i)=>{
                g.Order = i;
                g.EventFieldsGroupEventFields.forEach((f, k)=>{
                    f.Order = k;
                });
            });
            if(params && params.id){
                axios.put(`/eventtypepage/${data.ID}`, data)
                .then(response=>{
                    this.setState({dirty: false});
                    this.props.context.pushAlert({variant:'success', message: 'Item updated.'});
                    this.props.context.hideLoading();
                    if(name==='SaveClose')
                    this.props.history.push('/dataview');
                }).catch(error=>{
                    this.props.context.pushAlert({variant:'danger', message: 'Error occured. Item was not updated.'});
                    console.log(error);
                    this.props.context.hideLoading();
                });
            } else {
                axios.post(`/eventtypepage`, data)
                .then(response=>{
                    this.setState({dirty: false});
                    this.props.context.pushAlert({variant:'success', message: 'Item created.'});
                    this.props.context.hideLoading();
                    if(name==='SaveClose')
                        this.props.history.push('/dataview');
                    else
                        this.props.history.push(`/dataentry/update/${response.data.id}`);
                }).catch(error=>{
                    this.props.context.pushAlert({variant:'danger', message: 'Error occured. Item was not created.'});
                    console.log(error);
                    this.props.context.hideLoading();
                });
            }
        })
        .catch(errors=>{
            const error = {};
            console.log(errors);
            errors.inner.forEach(el=>{
                set(error,el.path, el.message);
            });
            this.setState({error:error});
        });
        
    }


    render(){
        const error = this.state.error;
        return(
            <form>
                <fieldset>
                    <legend>Basic Settings</legend>
                        <input name="ID" type="hidden" value={this.state.data.ID}/>
                        <BootstrapInput name="Name" label="Name" value={this.state.data.Name} error={error} onChange={this.onChange}/>
                        <BootstrapInput name="PageType" label="Page Type" options={pageTypes} onChange={this.onChange} error={error} value={this.state.data.PageType} component={Select}/>
                        <BootstrapInput name="Roles" label="Available for Roles" onChange={this.onChange} defaultOptions error={error} value={this.state.data.Roles} isMulti={true} loadOptions={this.getRoles} component={AsyncSelect}/>
                        <BootstrapInput name="EventType" label="For Event Type" onChange={this.loadET} loadOptions={this.getETs} error={error} value={this.state.data.EventType} component={AsyncSelect}/>
                        <BootstrapInput name="DisableAttachments" value={this.state.data.DisableAttachments} label="Disable Attachments" onChange={this.onChange} component={Switch} disableLabel/>
                        <hr/>
                </fieldset>
                    <BootstrapInput name="AllowAssociatedEvents" value={this.state.data.AllowAssociatedEvents} label="Allow Associated Events" onChange={this.onChange} component={Switch} disableLabel/>
                
                    {this.state.data.AllowAssociatedEvents ? (
                        <fieldset>
                            <legend>
                                Associated Views
                                <FontAwesomeIcon className="add-icon" icon="plus" onClick={this.addAssocView}/>
                            </legend>

                            {this.state.data.AssociatedEventDataViews.map((el, i)=>(
                                <AssocView key={i} index={i} name={`AssociatedEventDataViews[${i}]`} error={error} value={el} onChange={this.onChange} onRemove={this.removeAssocView}/>
                            ))}
                            <hr/>
                        </fieldset>
                    ) : null}
                    
                <fieldset>
                    <legend>
                        Field Groups
                        <FontAwesomeIcon className="add-icon" icon="plus" onClick={this.addGroup}/>
                    </legend>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="groups" type="GROUPS">
                            {(provided, snapshot)=>(
                                <div ref={provided.innerRef}>
                                    <Groups onRemove={this.removeGroup} onChange={this.onChange} groups={this.state.data.Groups} error={error}/>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <hr/>
                </fieldset>
                <ButtonGroup>
                    <Button onClick={this.onClose} variant="warning">Exit</Button>
                    <Button onClick={this.handleSubmit} name="Save" variant="primary">Save</Button>
                    <Button onClick={this.handleSubmit} name="SaveClose" variant="success">Save &amp; Exit</Button>
                </ButtonGroup>
            </form>
        );
    }
}

export default withContext(Form);

