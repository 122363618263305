import React from 'react';
import Tile from '../../components/LandingPageCard/LandingPageCard';
import { Col, Row, Container } from 'react-bootstrap';
import NoTilesMessage from './NoTilesMessage';
import axios from '../../helpers/axiosWebEntry';
import { withRouter } from 'react-router-dom';
import withContext from '../../hoc/withContext';
import { isUrlAbsolute } from '../../helpers/utils';



class Landing extends React.Component {
    state = {
        tiles: [],
        current: undefined
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        this.loadTile(params.id);
    }

    loadTile = (id) => {
        this.props.context.showLoading();
        axios.get(`/landingtile/${id || undefined}`)
            .then(response => {
                const data = JSON.parse(response.data);
                const current = { ...data };
                current.Image = isUrlAbsolute(current.Image) ? current.Image : `${process.env.REACT_APP_BLOB_STORAGE_ROOT}${current.Image}`
                const tiles = data.Children;
                tiles.sort((a, b) => a.Order > b.Order ? 1 : -1);
                tiles.forEach((item) => {
                    item.Image = isUrlAbsolute(item.Image) ? item.Image : `${process.env.REACT_APP_BLOB_STORAGE_ROOT}${item.Image}`;
                });
                this.setState({ current: current, tiles: tiles });
                this.props.context.setHeading(this.props.context.t(current.Title));
                this.props.context.hideLoading();
            })
            .catch(error => {
                console.log(error);
                this.props.context.hideLoading();
            });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.match.url !== this.props.match.url) {
            const { match: { params } } = nextProps;
            this.loadTile(params.id);
        }
    }

    removeTile = (id) => {
        if (!window.confirm('Do you want to remove the Tile?'))
            return;
        this.props.context.showLoading();
        axios.delete(`/landingtile/${id}`)
            .then(response => {
                this.props.context.pushAlert({ variant: 'success', message: 'Item deleted.' });
                let tiles = [...this.state.tiles];
                tiles = tiles.filter((el) => {
                    return el.ID !== id;
                });
                this.setState({ tiles: tiles });
                this.props.context.hideLoading();
            })
            .catch(error => {
                console.log(error);
                this.props.context.pushAlert({ variant: 'danger', message: 'Unable to delete item.' });
                this.props.context.hideLoading();
            });
    }

    render() {
        return (
            <React.Fragment>
                <Container className="landing-container">
                    <Row className="d-flex justify-content-center">
                        {this.state.tiles.length ? this.state.tiles.map((t, i) => (
                            <Col lg={3} xs={12} className="d-flex justify-content-center"><Tile {...t} context={this.props.context} onRemoveTile={this.removeTile} allowEdit={this.props.context.isAdmin()} key={i} /></Col>
                        )) : <NoTilesMessage hidemessage={this.props.context.isMessageHidden} />}
                        {this.props.context.isAdmin() ?
                            (
                                <Col lg={3} xs={12}>
                                    <Tile
                                        Title={this.props.context.t('Add Tile')}
                                        Link={this.state.current ?
                                            `/landingtile/create/${this.state.current.ID}/${this.state.tiles.length ? this.state.tiles[this.state.tiles.length - 1].Order + 1 : ''}`
                                            :
                                            `/landingtile/create/`
                                        }
                                        Image="https://via.placeholder.com/200x150"
                                        context={this.props.context}
                                    />
                                </Col>
                            ) : null}

                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default withRouter(withContext(Landing));